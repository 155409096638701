export const Installed = () => {
  const param = new URLSearchParams(document.location.search);

  let source = param.get("source");
  let creative_id = param.get("creative_id");
  let ad_campaign_id = param.get("ad_campaign_id");
  let offer = param.get("offer");
  let creo_id = param.get("creo_id");
  let external_id = param.get("external_id");
  let site_id = param.get("site_id");
  let cost = param.get("cost");
  let sub_id_1 = param.get("sub_id_1");
  let sub_id_2 = param.get("sub_id_2");
  let sub_id_3 = param.get("sub_id_3");
  let id = param.get("id");

  if (!id) {
    id = localStorage.getItem("id");
  }

  if (!sub_id_1) {
    sub_id_1 = localStorage.getItem("sub_id_1");
  }

  if (!sub_id_2) {
    sub_id_2 = localStorage.getItem("sub_id_2");
  }

  if (!sub_id_3) {
    sub_id_3 = localStorage.getItem("sub_id_3");
  }

  if (!cost) {
    cost = localStorage.getItem("cost");
  }

  if (!creo_id) {
    creo_id = localStorage.getItem("creo_id");
  }

  if (!external_id) {
    external_id = localStorage.getItem("external_id");
  }

  if (!site_id) {
    site_id = localStorage.getItem("site_id");
  }

  if (!source) {
    source = localStorage.getItem("source");
  }

  if (!creative_id) {
    creative_id = localStorage.getItem("creative_id");
  }

  if (!ad_campaign_id) {
    ad_campaign_id = localStorage.getItem("ad_campaign_id");
  }

  if (!offer) {
    offer = localStorage.getItem("offer");
  }

  setTimeout(() => {
    if (id === "1011") {
      window.location.href = `https://arumo.top/vsDWnyDy?creative_id=${creative_id}&source=${source}`;
    } else if (id === "1007") {
      window.location.href = `https://arumo.top/tf6S7FF5?creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&source=${source}`;
    } else if (id === "1009") {
      window.location.href = `https://arumo.top/sL2Sd9S5?external_id=${external_id}&creo_id=${creo_id}&ad_campaign_id=${ad_campaign_id}&site_id=${site_id}`;
    } else if (id === "1008") {
      window.location.href = `https://arumo.top/wPJBrJ69?external_id=${external_id}&creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&site_id=${site_id}`;
    } else if (id === "1438") {
      window.location.href = `https://arumo.top/rp8n6wPq?external_id=${external_id}&creo_id=${creo_id}&ad_campaign_id=${ad_campaign_id}&site_id=${site_id}&sub_id_1=${sub_id_1}`;
    } else if (id === "1439") {
      window.location.href = `https://arumo.top/VXx5HHLw?external_id=${external_id}&creative_id=${creo_id}&ad_campaign_id=${ad_campaign_id}&site_id=${site_id}`;
    } else if (id === "1440") {
      window.location.href = `https://arumo.top/3hW8LvTM?cost=${cost}&external_id=${external_id}&creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&site_id=${site_id}`;
    } else if (id === "1441") {
      window.location.href = `https://arumo.top/wCDcj4xJ?external_id=${external_id}&creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&source=${source}`;
    } else if (id === "1442") {
      window.location.href = `https://arumo.top/3YPm8t4V?ad_campaign_id=${ad_campaign_id}`;
    } else {
      window.location.href = `https://arumo.top/vsDWnyDy?creative_id=${creative_id}&source=${source}`;
    }
  }, 200);

  setTimeout(() => {
    document.getElementById("openApp").click();
  }, 17400);

  return <div></div>;
};
