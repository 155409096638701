export const New = () => {
  return (
    <div className="new">
      <p className="new__title">Какие новости?</p>
      <p className="new__description">Обновлен раздел Бонусов и Акций</p>
      <p className="new__description">
        Добавлено месторасположение ближайшего Отделения Sber Bank, Банкомата
        или Терминала на карте.
      </p>
      <p className="new__description">
        Добавлена поддержка Face ID и Touch ID.
      </p>
      <p className="new__description">
        Исправлена ошибка с push-уведомлениями, которая возникала у некоторых
        пользователей Android 10.
      </p>
      {/* <p className="new__description">
        Fixed an issue where text in Settings was very small for Android users.
      </p> */}
    </div>
  );
};
